import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GalleryPunk from "../components/galleryPunk"

const Punk = () => (
  <Layout>
    <SEO title="Punk photography around the world (2014-2020)" />
    <h1>Punx not dead</h1>
    <p>A small selection of pictures taken in the last six years all around the globe. In Barcelona, Spain, different countries of Europe, the US, South Korea and Japan.</p>
    <div class="gallery">
    	<GalleryPunk />
    </div>
  </Layout>
)

export default Punk
